import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})

export class CommonserviceService {
  serviceHost = environment.serviceHost;
  ProfileFile: any;



  public change: EventEmitter<any> = new EventEmitter();

  private NocPay = new BehaviorSubject<string>('');
  NOCPay = this.NocPay.asObservable();

  private FeesVal = new BehaviorSubject<string>('');
  Feesval = this.FeesVal.asObservable();

  private SiteInfo = new BehaviorSubject<string>('');
  Siteinfo = this.SiteInfo.asObservable();

  private ipaddress = new BehaviorSubject<any>('');
  Ipaddress = this.ipaddress.asObservable();

  UploadImg: any = {};
  ipdata: any;

  private GetTheme = new BehaviorSubject<string>('');
  getTheme = this.GetTheme.asObservable();

  private userImage = new BehaviorSubject<string>('');
  userHeaderImage = this.userImage.asObservable();
  language: string;
  constructor(private http: HttpClient) {
    this.http.get<{ ip: string }>('https://api.ipify.org/?format=json').subscribe(data => {
      this.ipdata = data;
      const datas = {
        ipaddress: this.ipdata.ip
      };
      localStorage.setItem('ip', this.ipdata.ip)

    });
   this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'
 console.log("lang",this.language)
  }


  changeTheme(Values): any {
    this.GetTheme.next(Values);
  }

  // POST FUNCTION ( Signin )
  postfunction(url, data): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('tag', 'web').set('lang', this.language);
    return this.http.post(this.serviceHost + url, data, { headers });
  }

  // Post Function ( Support Ticket )
  Postfunction(url, data, token): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('authorization', token).set('tag', 'web').set('lang', this.language).set('ipaddress', this.ipdata.ip);
    return this.http.post(this.serviceHost + url, data, { headers });
  }

  PostFunction(url, token): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('authorization', token).set('tag', 'web').set('lang', this.language);
    return this.http.post(this.serviceHost + url, { headers });
  }

  // GET FUNCTION
  Getfunction(url, id): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('authorization', id).set('tag', 'web').set('lang', this.language);
    return this.http.get(this.serviceHost + url, { headers });
  }

  getfunction(url): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('tag', 'web').set('lang', this.language);
    return this.http.get(`${this.serviceHost + url}`, { headers });
  }

  // PUT FUNCTION
  putfunction(url, token, data): Observable<any> {
    this.language = (localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined) ? localStorage.getItem("lang") :'en'

    const headers = new HttpHeaders().set('authorization', token).set('tag', 'web').set('lang', this.language);
    return this.http.put(this.serviceHost + url, data, { headers });
  }

  getCaptchaKey() {
    // return '6LdwMMgUAAAAAGteaHHXv3OTn8I9nYXO-USZVdbk';
    return '6LexBskUAAAAAEkRpWGzahXA9YUaec_zo865PBQB';
  }

  changeImageToheader(Img): any {
    this.userImage.next(Img);
  }


  NoopayFuction(Values): any {
    this.NocPay.next(Values);
  }

  FeesFuction(Fees): void {
    this.FeesVal.next(Fees);
  }

  SiteFunction(val): any {
    this.SiteInfo.next(val);
  }

  IpFunction(ip): any {
    this.ipaddress.next(ip);
  }

  postFile(url, fileToUpload, token): Observable<any> {
    const headers = new HttpHeaders().set('token', token);
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post(this.serviceHost + url, formData, { headers });
  }
}
