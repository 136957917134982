import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { from, fromEvent } from 'rxjs';
import { Location, LocationStrategy, PlatformLocation } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-underconstruction',
  templateUrl: './underconstruction.component.html',
  styleUrls: ['./underconstruction.component.css']
})


export class UnderconstructionComponent implements OnInit {
  title = 'BITSPADE | Under Construction | BITSPADE';
  constructor(location: LocationStrategy, private titleService: Title) {
    window.history.pushState(null, null, window.location.href);
    location.onPopState(() => {
      window.history.pushState(null, null, window.location.href);
    });
  }



  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }

}
