import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnderconstructionComponent } from './underconstruction/underconstruction.component';
// import { TokenDetailsComponent } from './token-details/token-details.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },  
  {
    path: 'aboutus',
    loadChildren: () => import('./staticpages/aboutus/aboutus.module').then(m => m.AboutusModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./staticpages/faq/faq.module').then(m => m.FaqModule),
  
  },
  {
    path: 'fees',
    loadChildren: () => import('./staticpages/fees-structure/fees-structure.module').then(m => m.FeesStructureModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./staticpages/supportpage/supportpage.module').then(m => m.SupportpageModule),
  },
  {
    path: 'assets-listing',
    loadChildren: () => import('./staticpages/tokenlisting/tokenlisting.module').then(m => m.TokenListingModule),
  },
  {
    path: 'assets-view',
    loadChildren: () => import('./staticpages/tokenlistdetails/tokenlistdetails.module').then(m => m.TokenlistdetailsModule),
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./staticpages/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./staticpages/privacy-policy/privacy-policy.module').then(m => m.PrivacypolicyModule),
  },
  {
    path: 'law-enforcement',
    loadChildren: () => import('./staticpages/law-enforcement/law-enforcement.module').then(m => m.LawEnforcementModule),
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./staticpages/compliance-disclosure/compliance-disclosure.module').then(m => m.ComplianceDisclosureModule),
  },
  {
    path: 'comming-soon',
    loadChildren: () => import('./comming-soon/comming-soon.module').then(m => m.CommingSoonModule),
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'under-construction', component: UnderconstructionComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
