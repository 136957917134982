import { Component, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { CommonserviceService } from './Service/CommonService/commonservice.service';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AuthService } from './Service/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})



export class AppComponent implements OnInit {
  title = 'BITSPADE | Cryptocurrency Exchange | BITSPADE';
  ipdata: any = {};
  IP: any = {};
  block_page: Boolean = false; ipDetails: any = {};
  theme: string;
  isrefreshed = false;
  @Output() themeCng = new EventEmitter<string>();
 

  constructor(private router: Router, private service: CommonserviceService, private authService: AuthService,
    private http: HttpClient, private toastr: ToastrService, private titleService: Title, private metaService: Meta) {

      // window.addEventListener('keydown',event=>{
      //   if(event.key =='r' || event.key == 'F5') this.isrefreshed = true;

      // });

      // window.addEventListener('beforeunload',event=>{
        
      //   if(!this.isrefreshed)
      //   {
      //     localStorage.clear();
      //   }

      // });


    this.http.get<{ ip: string }>('https://api.ipify.org/?format=json').subscribe(data => {
      this.ipdata = data;
      const datas = {
        ipaddress: this.ipdata.ip
      };
      localStorage.setItem('ip', this.ipdata.ip);

      if(this.ipdata) 
        this.getIPInformation()
    });


   
  }


  ngOnInit(): void {
    console.log("============LANDING PAGE=============")
    const homebodycls = document.getElementsByTagName('body')[0];
    homebodycls.classList.add('innerbodybg');
    homebodycls.classList.remove('homebodybg');
    homebodycls.classList.remove('TradePage');
    this.ipdata.ip = localStorage.getItem('ip');

    this.theme = localStorage.getItem('Theme');
    if (this.theme == "darktheme") {
      this.themeChange()
    }


    // localStorage.removeItem("Theme")
    

    this.auto_logout();
    this.auto_logout_mobile();
    this.account_block();
    this.ip_block();
    this.metaContent();
    this.siteMaintain();
  }
  themeChange() {
    var element = document.body;
    element.classList.toggle("dark-mode");
    let index = element.className.indexOf("dark-mode");

    if (index == -1) {
      this.theme = "whitetheme";
      localStorage.setItem("Theme", this.theme);
      // sessionStorage.setItem("Theme", this.theme);

      this.themeCng.emit(this.theme);
    }
    else {
      this.theme = "darktheme";
      localStorage.setItem("Theme", this.theme);
      // sessionStorage.setItem("Theme", this.theme);

      this.themeCng.emit(this.theme);
    }
    this.service.changeTheme(this.theme);
  }
  metaContent(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'BITSPADE, World’s Leading Cryptocurrency Exchange, Leading Cryptocurrency Exchange, Cryptocurrency Exchange, Leading Cryptocurrency trading, Bitcoin Exchange, Bitcoin Trading, Buy Bitcoin, Buy BTC, Digital Asset Exchange' },
      { name: 'description', content: 'Buy, Sell and Trade Bitcoin & other cryptocurrencies from the world’s leading cryptocurrency exchange, BITSPADE.' },
    ]);
  }

  siteMaintain(): any {
    
  }

  account_block(): void {
    
  }

  auto_logout(): void {
   
  }

  auto_logout_mobile(): void {
  
  }

  ip_block(): void {
    
  }


  getIPInformation(){
    let ip = localStorage.getItem("ip");
    this.http.get('https://ipapi.co/json').subscribe(res => {
      this.ipDetails = res;
      localStorage.setItem("Timezone", this.ipDetails.timezone);
      localStorage.setItem("Country", this.ipDetails.country_name);
    })
  }
}
