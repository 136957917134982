export const environment = {
  production: true,
  serviceHost: '',
  ImageuploadURL: '',
  serviceHostLIQ: '', 
}






